<template>
     <div class="main">
          <div>
               <h1>About Us</h1>

               <div class="about">
                    <h3 class="qn">
                         Who has built Slyde.ai ? <br />
                         Who has dared to step forward to cut this Gordian Knot of Slide Design ?
                    </h3>
                    <div class="ans">
                         <p>
                              We’re desktop warriors like yourself, suffering from PowerPoint fatigue. We are passionate about productivity and
                              communication design and striving to eliminate the pain of creating, editing, re-editing, and re-re-editing slides.
                         </p>

                         <p>
                              Over the last two decades, we have created countless presentations for internal and external teams on HR, product,
                              marketing, operations, strategy, and many other topics.
                         </p>

                         <p>
                              We have distilled our 20,000+ slide creation experience to build Slyde.ai
                         </p>

                         <p>
                              Slide creation is a burden for 9 out of 10 users who lack design skills to create impactful content quickly.
                         </p>
                         <p>
                              Slyde.ai has trained COGNITIVE AI with DESIGN THINKING principles to make content consumable, accessible, and aesthetic.
                              It guides users to edit layout, theme, colors, fonts, and many other design elements.
                         </p>

                         <p>
                              We can’t wait for you to try this out and iterate towards a mightier storytelling experience.
                         </p>
                         <p>
                              If you wish to talk further, please Contact Us. We’re always eager to learn more.
                         </p>
                    </div>
               </div>
          </div>
          <!-- <b-button @click="clickMe" class="coral"> Click Me</b-button>
          <b-button type="is-coral" class="worksans">Primary</b-button> -->
     </div>
</template>

<script>
     import axios from "axios";
     export default {
          data() {
               return {
                    email: "",
               };
          },
     };
</script>

<style lang="scss" scoped>
     @import "@/assets/styles/variables.scss";

     .main {
          display: block;

          min-height: auto;
          padding-bottom: 30px;
     }
     .darkmode {
          .email {
               background: $bg_secondary_dark !important;
               box-shadow: 0px 6.26856px 25.0742px $shadow_dark !important;
          }
          .about p {
               color: rgba(242, 242, 242, 0.8);
          }
     }

     .about {
          text-align: left;

          max-width: 700px !important;
          margin: auto;

          .qn {
               font-size: 25px;

               @media only screen and (max-width: 600px) {
                    font-size: 20px;
               }
          }
          .ans {
               text-align: left;
               margin-top: 20px;
               font-size: 17px;
               color: #73738d;

               p {
                    margin-bottom: 10px;
               }
          }
          padding-bottom: 30px;
     }
     h1 {
          margin-bottom: 80px;
          @media only screen and (max-width: 600px) {
               margin-bottom: 30px;
          }
     }
     .columns {
          margin: 0;
          padding: 0;
     }

     @media only screen and (max-width: 600px) {
          .main {
               padding: 10px;
          }
          h1 {
               font-size: 28.329px;
          }

          h4 {
               font-size: 15.8125px;
          }
          .video {
               margin-top: 30px;
               padding: 0 13px;
          }

          .emailcontainer {
               position: relative;
               margin: auto;
               margin-top: 40px;
               margin-bottom: 45px;

               .dots {
                    position: absolute;
                    bottom: -15px;
                    right: -10px;
                    width: 40px;
                    z-index: 10;
               }
               .circle {
                    display: none;
                    position: absolute;
                    top: -25px;
                    left: -35px;
                    width: 60px;
                    z-index: 1;
               }
               .email {
                    padding: 30px 14px 40px;

                    h3 {
                         font-weight: 600;
                         font-size: 18.28px;
                    }
               }
          }
     }
</style>
<style lang="scss">
     .emailinput {
          input {
               height: 47px !important;
          }
     }
</style>
